<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入手机号" suffix-icon="el-icon-search" v-model="name"></el-input>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0;display: flex; align-items: center; justify-content: space-between;">
      <el-button type="primary" @click="handleAdd">新增 <i class="el-icon-circle-plus-outline"></i></el-button>
        <div>
          <el-upload
                  :action= "API_URL+'/member/import?uid='+user.id"
                  :show-file-list="false"
                  accept="xlsx"
                  :on-success="handleExcelImportSuccess"
                  style="display: inline-block">
            <el-button type="primary" class="ml-5">批量导入 <i class="el-icon-bottom"></i></el-button>
          </el-upload>
          <el-button type="primary" @click="exp" class="ml-5">批量导出 <i class="el-icon-top"></i></el-button>

        </div>
    </div>

    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
<!--      <el-table-column type="selection" width="55"></el-table-column> -->
<!--      <el-table-column prop="id" label="ID" width="80" sortable></el-table-column> -->
      <el-table-column prop="mobile" label="手机号"></el-table-column>
      <el-table-column prop="pid" label="归属管理员">
          <template slot-scope="scope">
              {{scope.row.user?scope.row.user.nickname:''}}
          </template>
      </el-table-column>
      <el-table-column prop="levelInfo" label="会员等级"></el-table-column>
      <el-table-column prop="qrCode" label="收款二维码"><template slot-scope="scope"><el-image style="width: 100px; height: 100px" :src="scope.row.qrCode" :preview-src-list="[scope.row.qrCode]"></el-image></template></el-table-column>
      <el-table-column prop="deviceId" label="手机设备信息"></el-table-column>
      <el-table-column prop="ipAddress" label="ip地址"></el-table-column>
      <el-table-column prop="state" label="状态">
          <template slot-scope="scope">
              <el-tag v-if="scope.row.state ===1">启用</el-tag>
              <el-tag v-if="scope.row.state ===2" type='danger'>禁用</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间"></el-table-column>

      <el-table-column label="操作"  width="200" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleEdit(scope.row)">编辑 </el-button>
          <el-button type="danger" @click="clearMember(scope.row)">重置数据 </el-button>
<!--          <el-popconfirm-->
<!--              class="ml-5"-->
<!--              confirm-button-text='确定'-->
<!--              cancel-button-text='我再想想'-->
<!--              icon="el-icon-info"-->
<!--              icon-color="red"-->
<!--              title="您确定删除吗？"-->
<!--              @confirm="del(scope.row.id)"-->
<!--          >-->
<!--            <el-button type="danger" slot="reference">删除 <i class="el-icon-remove-outline"></i></el-button>-->
<!--          </el-popconfirm>-->
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="50%" >
      <el-form label-width="120px" size="small">
        <el-form-item label="手机号">
          <el-input v-model="form.mobile" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="会员等级">
            <el-radio-group v-model="form.levelInfo">
                <el-radio-button label="A"></el-radio-button>
                <el-radio-button label="B"></el-radio-button>
                <el-radio-button label="C"></el-radio-button>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="收款二维码">
            <el-upload class="avatar-uploader" :action=UPLOAD_URL :show-file-list="false" :on-success="handleImgUploadSuccess" >
                <img v-if="form.qrCode" :src="form.qrCode" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
        </el-form-item>
        <el-form-item label="手机设备信息">
          <el-input v-model="form.deviceId" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="ip地址">
          <el-input v-model="form.ipAddress" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态">
            <el-radio-group v-model="form.state">
                <el-radio-button label="1">启用</el-radio-button>
                <el-radio-button label="2">禁用</el-radio-button>
            </el-radio-group>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Member",
  data() {
    return {
      tableData: [],
      listdata: [],
      total: 0,
      id:'',
      pageNum: 1,
      pageSize: 10,
      name: "",
      form: {qrCode:''},
      dialogFormVisible: false,
      multipleSelection: [],
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {}
    }
  },
  // 钩子函数 页面渲染后加载
  created() {
//    this.id = location.search.split('=')[1]
    this.load()
  },
  // 方法
  methods: {
    // 获取所有的数据
    list() {
      this.request.get("/member").then(res => {
        this.listdata = res.data
      })
    },
    // 分页查询数据
    load() {
      this.request.get("/member/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },

    // 新增或者更新数据  当id为空新增，id不为空更新
    save() {
      this.request.post("/member", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        }
      })
    },
    // 新增按钮点击事件 打开弹出框
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {qrCode:''}
      this.$nextTick(() => {
        if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
      })
    },
    // 编辑按钮点击事件 打开弹出框
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
       this.$nextTick(() => {
         if(this.$refs.img) {
           this.$refs.img.clearFiles();
         }
         if(this.$refs.file) {
          this.$refs.file.clearFiles();
         }
       })
    },
      //清空用户信息
      clearMember(row){
          row.levelInfo='A'
          row.qrCode=''
          row.deviceId=''
          row.ipAddress=''
          row.state=1
          this.request.post("/member", row).then(res => {
              if (res.code === '200') {
                  this.$message.success("保存成功")
                  this.load()
              }
          })
      },
    // 根据id删除一行数据
    del(id) {
      this.request.delete("/member/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },

    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/member/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        }
      })
    },
    reset() {
      this.name = ""
      this.load()
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    handleFileUploadSuccess(res) {
      this.form.file = res
    },
    handleImgUploadSuccess(res) {
      this.form.qrCode = res
    },
    download(url) {
      window.open(url)
    },
    exp() {
      window.open(this.API_URL+"/member/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    }
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
.avatar-uploader {
  text-align: left;
  padding-bottom: 10px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 138px;
  height: 138px;
  line-height: 138px;
  text-align: center;
}
.avatar {
  width: 138px;
  height: 138px;
  display: block;
}
</style>
